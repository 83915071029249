import useImmutableSWR from 'swr'
// types and utils
import { errorHandler } from '../../utils'
import { PlanPathsEnum } from './types'
// api
import { getPlans, getPlan } from './plansApi'

interface IQuery {
  searchQuery: string
  page: number
  limit: number
}

export const useSearchPlans = (queryObj: IQuery) => {
  const { searchQuery, page, limit } = queryObj
  const offset = page * limit

  let query = searchQuery
  if (page > 0) {
    query += !!searchQuery ? `&Offset=${offset}` : `?Offset=${offset}`
  }

  const { data, error } = useImmutableSWR(`${PlanPathsEnum.plans}${query}`, (url, query) => getPlans(url, query), {
    shouldRetryOnError: false,
    onError: (error: any) => {
      //TODO Implement Error Handler after the backend fixes it
      errorHandler.handleError(error)
    },
  })

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useGetPlan = (planId: string) => {
  const { data, error } = useImmutableSWR(`${PlanPathsEnum.plans}/${planId}`, (url) => getPlan(url), {
    shouldRetryOnError: false,
    //TODO Handle Error
    onError: (error: any) => {
      errorHandler.handleError(error)
    },
  })

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}
