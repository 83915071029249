import styled, { ColorsEnum, css } from 'styled-components'

interface IStyles {
  type?: 'button' | 'submit'
  variant?: 'outlined' | 'contained'
  size?: 'largeInline' | 'normal' | 'large' | 'small'
  color?: ColorsEnum
  invertOnHover?: true
  disabled?: boolean
}

interface IProps extends IStyles {
  children: React.ReactNode
  dataTest?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button = ({ children, type, variant, size, color, invertOnHover, dataTest, disabled, onClick }: IProps) => {
  return (
    <StyledButton
      type={type || 'button'}
      variant={variant || 'contained'}
      color={color}
      size={size}
      invertOnHover={invertOnHover}
      onClick={onClick}
      data-test={dataTest || 'button'}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button<IStyles>`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.commons.light};
  background: ${({ theme, color }) => (color ? theme.colors[color].normal : theme.colors.main.normal)};
  border: 1px solid ${({ color, theme }) => (color ? theme.colors[color].normal : theme.colors.main.normal)};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: ${({ theme }) => theme.typography.h3};
  box-shadow: ${({ theme }) => theme.shadows.normal};

  ${({ size, theme }) =>
    size === 'large' &&
    css`
      padding: 0.875rem 4rem;
      font-size: ${theme.typography.h2};
    `}

  ${({ size, theme }) =>
    size === 'small' &&
    css`
      padding: 0.325rem 1rem;
      font-size: ${theme.typography.small};
    `}

  ${({ size }) =>
    size === 'largeInline' &&
    css`
      padding-inline: 3rem;
    `};

  ${({ color, variant, theme }) =>
    variant === 'outlined' &&
    css`
      font-weight: 700;
      background: transparent;
      color: ${color ? theme.colors[color].normal : theme.colors.main.normal};
      border: 1px solid ${color ? theme.colors[color].normal : theme.colors.main.normal};
    `};

  &:hover {
    ${({ invertOnHover, color, theme, variant }) =>
      invertOnHover &&
      css`
        background: transparent;
        color: ${color ? theme.colors[color].normal : theme.colors.main.normal};
        border: 1px solid ${color ? theme.colors[color].normal : theme.colors.main.normal};

        ${variant === 'outlined' &&
        css`
          color: ${theme.colors.commons.light};
          background: ${color ? theme.colors[color].normal : theme.colors.main.normal};
        `};
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `};
`
