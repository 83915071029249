import styled from 'styled-components'
// components
import LoginForm from './LoginForm'
// styles and assets
import LogoImg from '../../assets/images/logo.svg'

const Login = () => {
  return (
    <Wrapper>
      <Logo>
        <img src={LogoImg} alt="Logo" width="35" height="33" />
        <LogoText>Outlift Content UI</LogoText>
      </Logo>

      <LoginForm />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
`
const Logo = styled.div`
  margin-top: -210px;

  img {
    margin-bottom: 20px;
  }
`

const LogoText = styled.h1`
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 35px;
`

export default Login
