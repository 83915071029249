import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-toastify/dist/ReactToastify.css'
// components
import App from './App'
// utils
import { history } from './utils'
// state
import { store } from './app/store'

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={6000}
          // hideProgressBar={true}
          theme="colored"
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover
        />
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
