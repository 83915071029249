import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
// components
import TablePaginationActions from './TablePaginationActions'
// types
import { IPagination } from './types'

interface IProps {
  pagination: IPagination
}

const TableFooterComponent = ({ pagination: { page, rowsPerPage, count, handleChangePage } }: IProps) => {
  return (
    <TableFooter sx={{ position: 'sticky', bottom: '0', backgroundColor: 'white' }}>
      <TableRow>
        <TablePagination
          count={count}
          page={page || 0}
          rowsPerPage={rowsPerPage || -1}
          rowsPerPageOptions={[]}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

export default TableFooterComponent
