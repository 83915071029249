// components
import { Container } from '../../../components'
import { PhonesList, AddPhoneNumberForm } from '.'
// types
import { IPhoneNumber } from '../types'

interface IProps {
  phoneNumbers: IPhoneNumber[]
  planId: string
}

const PlanPhonesList = ({ phoneNumbers, planId }: IProps) => {
  return (
    <Container dataTest="planPhonesList">
      {phoneNumbers.length > 0 && <PhonesList />}

      <AddPhoneNumberForm planId={planId} />
    </Container>
  )
}

export default PlanPhonesList
