export enum PlanPathsEnum {
  plans = '/api/v1/plans',
}

export interface IGetPlansResponse {
  count: number
  limit: number
  offset: number
  data: IPlan[]
}

export interface IAddPhoneNumberRequest {
  e164Format: string
  primary: boolean
}

export interface IRemovePhoneNumberRequest {
  id: string
}

export interface ISearchQuery {
  name: string
  phoneNumber: string
  noPhoneNumber: boolean
  hasPhoneNumber: boolean
}

export interface IPlan {
  id: string
  name: string
  payer: IPayer
  planType: IPlanType
  phoneNumbers: IPhoneNumber[]
  created: string
  updated: string
}

export interface IPayer {
  id: string
  name: string
}

export interface IPlanType {
  id: string
  description: string
  channel: IPlanTypeChannel
}

export interface IPlanTypeChannel {
  id: string
  description: string
}

export interface IPhoneNumber {
  id: string
  e164Format: string
  primary: boolean
}

export interface IQuery {
  searchQuery: string
  page: number
  limit: number
}

export interface IFilter {
  path: string
  operator: string
  value: string
}
