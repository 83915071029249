import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
// types
import { IGetPlansResponse, IPhoneNumber, IPlan, ISearchQuery } from './types'
// utils
import { uriEncodeQuery } from './utils'

export interface IInitialState {
  plans: IGetPlansResponse
  plan: null | IPlan
  searchQuery: ISearchQuery
}

const initialState: IInitialState = {
  plans: {} as IGetPlansResponse,
  plan: null,
  searchQuery: { name: '', phoneNumber: '', noPhoneNumber: false, hasPhoneNumber: false },
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<ISearchQuery>) => {
      if (state.searchQuery !== action.payload) state.searchQuery = action.payload
    },
    setPlans: (state, action: PayloadAction<IGetPlansResponse>) => {
      state.plans = action.payload
    },
    setPlan: (state, action: PayloadAction<IPlan | null>) => {
      state.plan = action.payload
    },
    addPhoneNumber: (state, action: PayloadAction<IPhoneNumber>) => {
      state.plan?.phoneNumbers.push(action.payload)
    },
    deletePhoneNumber: (state, action: PayloadAction<{ phoneId: string }>) => {
      if (state.plan) {
        const { phoneId } = action.payload
        state.plan.phoneNumbers = [
          ...state.plan?.phoneNumbers.filter((phoneNumber) => (phoneNumber.id === phoneId ? false : true)),
        ]
      }
    },
  },
  // extraReducers: (builder) => {
  // },
})

export const getSearchQuery = (state: RootState) => uriEncodeQuery(state.plans.searchQuery)

export const { setSearchQuery, setPlans, setPlan, addPhoneNumber, deletePhoneNumber } = plansSlice.actions

export default plansSlice.reducer
