import { useState } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// components
import { Button } from '../../../components'
// types
import { ISearchQuery } from '../types'
// state
import { useAppDispatch, useAppSelector } from '../../../app/store'
import { setSearchQuery } from '../plansSlice'

const SearchForm = () => {
  // global state
  const { searchQuery } = useAppSelector((state) => state.plans)

  // hooks
  const dispatch = useAppDispatch()

  // local state
  const [data, setData] = useState<ISearchQuery>(searchQuery)

  // handlers
  const handleSumbit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    //TODO Add condition of form not to be empty
    dispatch(setSearchQuery(data))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, type } = e.target as HTMLInputElement
    setData((prev) => ({
      ...prev,
      [id]: type === 'checkbox' ? checked : value,
    }))
  }

  // variables
  const { name, phoneNumber, noPhoneNumber, hasPhoneNumber } = data

  return (
    <Wrapper>
      <h2>Criteria</h2>

      <form data-test="plansSearchForm">
        <FormGroup>
          <TextField
            name="name"
            id="name"
            size="small"
            label="Name"
            type="search"
            onChange={handleChange}
            defaultValue={name}
            fullWidth
            data-test="searchPlansNameInput"
          />

          <TextField
            name="phoneNumber"
            id="phoneNumber"
            size="small"
            label="Phone number"
            type="search"
            onChange={handleChange}
            defaultValue={phoneNumber}
            fullWidth
            data-test="searchPlansPhoneInput"
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={hasPhoneNumber || !!phoneNumber}
                  name="noPhoneNumber"
                  id="noPhoneNumber"
                  onChange={handleChange}
                  checked={noPhoneNumber && !phoneNumber}
                  data-test="searchPlansNoPhoneCheckbox"
                />
              }
              label="No Phone Number"
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={noPhoneNumber || !!phoneNumber}
                  name="hasPhoneNumber"
                  id="hasPhoneNumber"
                  onChange={handleChange}
                  checked={hasPhoneNumber || !!phoneNumber}
                  data-test="searchPlansWithPhoneCheckbox"
                />
              }
              label="Has Phone Number"
            />

            {/* Temprorarly removed based on the comment on the PR: https://github.com/outbound-ai/outlift-content-ui/pull/1  */}
            {/* <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="conversationSuccess"
                    id="conversationSuccess"
                    onChange={handleChange}
                    checked={conversationSuccess}
                  />
                }
                label="Conversation Success"
              />
              <TextField
                name="percent"
                id="percent"
                onChange={handleChange}
                size="small"
                type="number"
                label="%"
                disabled={!conversationSuccess}
                defaultValue={percent}
                InputProps={{
                  startAdornment: <InputAdornment position="start">&lt;</InputAdornment>,
                  inputProps: {
                    min: 0,
                    max: 100,
                  },
                }}
              />
            </FormControl> */}
          </FormGroup>
        </FormGroup>

        <Button
          type="submit"
          size="largeInline"
          variant="contained"
          dataTest="searchPlansSubmit"
          onClick={handleSumbit}
        >
          Search
        </Button>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 2em;
  padding-inline: 0.5em;

  button {
    display: block;
    margin-inline: auto;
    text-align: center;
  }
`

const FormGroup = styled.div`
  :first-child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    margin-block-end: 0.5em;
  }
`
export default SearchForm
