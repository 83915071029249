// types and utils
import { IUser, IUserProfile } from '../types/userTypes'

export enum StorageEnum {
  accessToken = 'accessToken',
  currentUser = 'currentUser',
  profile = 'profile',
}

export const getAccessToken = () => sessionStorage.getItem(StorageEnum.accessToken) || ''

export const setAccessToken = (accessToken: string) =>
  sessionStorage.setItem(StorageEnum.accessToken, accessToken || '')

export const setProfile = (updatedProfile: IUserProfile) => {
  sessionStorage.setItem(StorageEnum.profile, JSON.stringify(updatedProfile))
}

export const setUser = (user: IUser) => {
  sessionStorage.setItem(StorageEnum.currentUser, JSON.stringify(user))
}

export const getUserAndProfile = () => {
  const currentUser: IUser = JSON.parse(sessionStorage.getItem(StorageEnum.currentUser) || '{}')
  // const profile: IUserProfile = JSON.parse(sessionStorage.getItem(StorageEnum.profile) || '{}')

  return { currentUser /* , profile */ }
}

export const removeUser = () => {
  sessionStorage.removeItem(StorageEnum.accessToken)
  sessionStorage.removeItem(StorageEnum.currentUser)
  sessionStorage.removeItem(StorageEnum.profile)
}

const moduleInterface = { getAccessToken, removeUser, setUser, getUserAndProfile, setProfile, setAccessToken }

export default moduleInterface
