import { Route, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
// state
import { useAppDispatch, useAppSelector } from '../../app/store'
import { setUserSession } from '../../slices/users/usersSlice'
// components
import Plans from '../Plans/Plans'
import Plan from '../Plan/Plan'
import Login from '../Login/Login'
// types and utils
import storage, { StorageEnum } from '../../utils/storage'
// routes
import AdminRoutes from './AdminRoutes'
import GuestRoutes from './GuestRoutes'
import AuthRoutes from './AuthRoutes'

const Router = () => {
  // global state
  const { /* profile, */ user } = useAppSelector((state) => state.users)

  // props and utils
  const dispatch = useAppDispatch()
  const loggedIn = sessionStorage.getItem(StorageEnum.accessToken) && sessionStorage.getItem(StorageEnum.currentUser)

  if (loggedIn && /* isEmpty(profile) || */ isEmpty(user)) {
    const { currentUser /* , profile */ } = storage.getUserAndProfile()
    dispatch(setUserSession({ currentUser }))
  }

  return (
    <>
      <GuestRoutes>
        <Route path="/login">{loggedIn ? <Redirect to="/" /> : <Login />}</Route>
        {!loggedIn && <Redirect to="/login" />}
      </GuestRoutes>
      <AuthRoutes>
        <Route exact path="/plans/:id" component={Plan} />
        <Route exact path="/plans" component={Plans} />
        <Route exact path="/" component={Plans} />
      </AuthRoutes>
      <AdminRoutes>{/* to be added */}</AdminRoutes>
    </>
  )
}

export default Router
