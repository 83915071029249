import styled from 'styled-components'

const scrollIntoView = (id: string, block?: ScrollLogicalPosition) => {
  if (document?.getElementById(id)) {
    const element = document.getElementById(id) as HTMLElement
    element.scrollIntoView({ behavior: 'smooth', block })
  }
}

interface IProps {
  id: string
  offset?: string
}
export const ScrollTarget = ({ id, offset }: IProps) => {
  return <Wrapper id={id} offset={offset} />
}
const Wrapper = styled.div<IProps>`
  position: relative;
  top: ${(props) => props.offset || '0'};
  background: transparent;
`

const dom = { scrollIntoView }
export default dom
