import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Container from '@mui/material/Container'
// components
import Button from '../Button/Button'
// state
import { useAppDispatch } from '../../app/store'
import { signOut } from '../../slices/users/usersApi'
// styles and assets
import Logo from '../../assets/images/logo.svg'
import Logout from '../../assets/images/logout.svg'

const Nav = () => {
  const dispatch = useAppDispatch()
  return (
    <Navbar>
      <Container maxWidth="xl">
        <ul>
          <li>
            <NavLink to="/" className="brand">
              <img src={Logo} alt="Logo" width="35" height="35" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/plans" activeClassName="active">
              Plans
            </NavLink>
          </li>
        </ul>
        <Button size="small" onClick={() => dispatch(signOut)} data-test="logout-btn">
          <img src={Logout} alt="Logout" />
          <span>Logout</span>
        </Button>
      </Container>
    </Navbar>
  )
}

export default Nav

const Navbar = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: ${(props) => props.theme.shadows.light};
  background: ${(props) => props.theme.colors.commons.light};
  z-index: 10;

  /* Container */
  div {
    display: flex;
    justify-content: space-between;
    /* Navigation */
    ul {
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
      }

      a {
        display: inline-block;
        position: relative;

        &.brand {
          padding-inline: 1rem;
        }

        &:not(.brand) {
          padding: 0.875rem 1.2rem;
        }

        &:not(.active):hover {
          background: ${(props) => props.theme.colors.secondary.light};
        }

        &.active:not(.brand)::after {
          content: '';
          position: absolute;
          inset-inline: 0;
          inset-block-end: 0;
          height: 5px;
          border-radius: ${(props) => props.theme.borderRadius};
          background: ${(props) => props.theme.colors.main.normal};
        }
      }
    }
    /* Logout */
    button {
      display: flex;
      align-self: center;
      width: 8em;
      justify-content: space-between;
      align-items: center;
      img {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`
