import styled, { css } from 'styled-components'

interface IStyles {
  mt?: string | number
  mb?: string | number
  p?: string | number
  minHeight?: string
  centeredContent?: true
  hasBorder?: true
  position?: 'relative'
}
interface IProps extends IStyles {
  children: React.ReactNode
  extraStyles?: IStyles
  dataTest?: string
}

const Container = ({ children, extraStyles, dataTest }: IProps) => {
  return <Wrapper data-test={dataTest} {...extraStyles}>{children}</Wrapper>
}

const Wrapper = styled.div<IStyles>`
  margin-top: ${({ mt }) => mt || '0'};
  margin-bottom: ${({ mb }) => mb || '0'};
  padding: ${({ p }) => p || '1em'};

  ${({ centeredContent }) =>
    centeredContent &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    `}

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.commons.dark};
      border-radius: ${({ theme }) => theme.borderRadius};
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}
`

export default Container
