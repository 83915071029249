import styled from 'styled-components'
// components
import { Container } from '../../../components'
// utils
import { formatDateWithAMPM } from '../../../utils'
// types
import { IPlan } from '../types'

interface IProps {
  plan: IPlan
}

const PlanSummary = ({ plan }: IProps) => {
  return (
    <Container dataTest="planSummaryContainer">
      <h2>Summary</h2>

      <Container extraStyles={{ hasBorder: true, minHeight: '20em' }}>
        <PlanSummaryRow label="Name" value={plan?.name || 'Missing'} />
        <PlanSummaryRow label="Type" value={plan?.planType?.description || 'Missing'} />
        <PlanSummaryRow label="Type Channel" value={plan?.planType?.channel?.description || 'Missing'} />
        <PlanSummaryRow label="Payer" value={plan?.payer?.name || 'Missing'} />
        <PlanSummaryRow label="Phone numbers" value={plan?.phoneNumbers?.length || 'Missing'} />
        <PlanSummaryRow label="Created" value={formatDateWithAMPM(plan?.created || 'Missing')} />
        <PlanSummaryRow label="Last update" value={formatDateWithAMPM(plan?.updated || 'Missing')} />
      </Container>
    </Container>
  )
}

const PlanSummaryRow = ({ label, value }: { label: string; value: string | number }) => (
  <Row>
    <b>{label}:</b> {value}
  </Row>
)

const Row = styled.p`
  margin-block: 1em;
`

export default PlanSummary
