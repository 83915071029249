import { ErrorMessagesEnum } from '../../types/errorTypes'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
// types and utils
import { ILoginCredentials, IUser, IUserProfile, ResetSuccessEnum } from '../../types/userTypes'
import ErrorHandler from '../../utils/errors'
// services
import userApi from './usersApi'

export const login = createAsyncThunk(
  'users/login',
  async ({ usernameField, password }: ILoginCredentials, { rejectWithValue }) => {
    try {
      return await userApi.login({ usernameField, password })
    } catch (error: any) {
      // If wrong credentials display the error on the login form
      if (error?.code === 'NotAuthorizedException') return rejectWithValue(true)
      // For other errors show notifications
      ErrorHandler.handleError(error, `${ErrorMessagesEnum.LoadProfile} ${ErrorMessagesEnum.ReportToAdministrator}`)
    }
  },
)

interface IUserState {
  user: IUser | Record<string, never>
  profile: IUserProfile | Record<string, never>
  loading: {
    login: boolean
    updateProfile: boolean
  }
  error: {
    login: boolean | string
    updateProfile: boolean | string
    fetchProfile: boolean | string
  }
  success: {
    updateProfile: boolean
  }
}

const initialState = {
  user: {},
  profile: {},
  loading: {
    login: false,
    updateProfile: false,
  },
  error: {
    login: false,
    updateProfile: false,
  },
  success: {
    updateProfile: false,
  },
} as IUserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      const { currentUser } = action.payload
      state.user = currentUser
    },
    logout: (state) => {
      state.user = {}
    },
    resetSuccess: (state, action: PayloadAction<ResetSuccessEnum>) => {
      if (state.success[action.payload]) state.success[action.payload] = false
    },
    updateLocalStateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { setUser, setUserSession, logout, resetSuccess, updateLocalStateUser } = userSlice.actions

export default userSlice.reducer
