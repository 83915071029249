import { toast } from 'react-toastify'

const error = (message: string | JSX.Element) => toast.error(message)
const success = (message: string | JSX.Element) => toast.success(message)

const notificator = {
  error,
  success,
}
export default notificator
