// types
import { notificator } from '../notificator'
import { ErrorCodesEnum, ErrorMessagesEnum } from './types'

const handleError = (error: any, message?: string) => {
  // Add the detail message from the error if we did not receive a message parameter
  if (!message && error?.response?.data?.value?.detail) {
    message = error.response.data.value.detail
  }
  switch (error?.response?.status) {
    case ErrorCodesEnum.NotFound:
      notificator.error(message || ErrorMessagesEnum.NotFound)
      break
    case ErrorCodesEnum.NotAuthorized:
      // usersApi.signOut()
      notificator.error(ErrorMessagesEnum.NotAuthenticated)
      break
    case ErrorCodesEnum.BadRequest:
      notificator.error(message || ErrorMessagesEnum.BadRequest)
      break
    default:
      notificator.error(ErrorMessagesEnum.Unknown)
  }
}

const errorHandler = { handleError }
export default errorHandler
