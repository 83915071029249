import { Auth } from 'aws-amplify'
//* types and utils
import { ILoginCredentials, ILoginResponse } from '../../types/userTypes'
// import { ErrorMessagesEnum } from '../../types/errorTypes'
import storage from '../../utils/storage'
import notificator from '../../utils/notificator/notificator'
// state
import { store } from '../../app/store'
import { logout } from './usersSlice'
import { ErrorMessagesEnum } from '../../types/errorTypes'

export const login = async ({ usernameField, password }: ILoginCredentials) => {
  const user: ILoginResponse = await Auth.signIn(usernameField, password)
  const {
    username: usernameResponse,
    attributes: { email, email_verified, family_name, given_name, phone_number, phone_number_verified },
  } = user
  const currentUser = {
    username: usernameResponse,
    email,
    email_verified,
    family_name,
    given_name,
    phone_number,
    phone_number_verified,
  }

  storage.setAccessToken(user?.signInUserSession?.accessToken?.jwtToken)
  storage.setUser(currentUser)

  return currentUser
}

export const signOut = async () => {
  try {
    await Auth.signOut()
    storage.removeUser()
    store.dispatch(logout())
  } catch (error: any) {
    notificator.error(ErrorMessagesEnum.SignOut)
  }
}

const moduleInterface = {
  login,
  signOut,
}

export default moduleInterface
