import styled from 'styled-components'
import Grid from '@mui/material/Grid'
// components
import { Layout } from '../../components'
import { SearchResults, SearchForm } from '../../slices/plans/components'

const Search = () => {
  return (
    <Layout>
      <Wrapper>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={2}>
            <SearchForm />
          </Grid>
          <Grid item xs={12} md={10}>
            <SearchResults />
          </Grid>
        </Grid>
      </Wrapper>
    </Layout>
  )
}

export default Search

const Wrapper = styled.div`
  min-width: min(90vw, ${(props) => props.theme.breakpoints.lg});
  margin-block: 0.325rem;
`
