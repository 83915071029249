import { Button, ConfirmationModal } from '../../../components'

interface IProps {
  handleDelete: () => Promise<void>
  isLoading: boolean
  phoneNumber: string
}

const RemovePhoneNumberButton = ({ handleDelete, isLoading, phoneNumber }: IProps) => {
  return (
    <ConfirmationModal
      message={`Are you sure you want to remove ${phoneNumber}?`}
      onConfirm={handleDelete}
      isLoading={isLoading}
      Toggler={
        <Button dataTest="planDeletePhoneToggler" variant="outlined" size="small" color="danger">
          X
        </Button>
      }
    />
  )
}

export default RemovePhoneNumberButton
