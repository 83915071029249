import styled from 'styled-components'
// components
import { Container, Nav } from '../../components'
// types
import { IChildrenNode } from '../../types/propTypes'

const Layout = ({ children }: IChildrenNode) => {
  return (
    <Wrapper>
      <Nav />
      <div className="nav-offset">
        <Container>{children}</Container>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > .nav-offset {
    margin-top: 3rem;
  }
`

export default Layout
