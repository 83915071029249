import { useState } from 'react'
import styled from 'styled-components'
// components
import { Button, Container, Loading } from '../../../components'
import { RemovePhoneNumberButton } from '.'
// types
import { IPhoneNumber } from '../types'
// utils
import { errorHandler, history, notificator, SuccessMessagesEnum } from '../../../utils'
// state
import { useAppDispatch } from '../../../app/store'
import { deletePhoneNumber } from '../plansSlice'
// services
import plansApi from '../plansApi'

interface IProps {
  phoneNumber: IPhoneNumber
  planId: string
}

const PhoneListPhoneNumber = ({ phoneNumber, planId }: IProps) => {
  // hooks
  const dispatch = useAppDispatch()

  // local state
  const [isLoading, setIsLoading] = useState(false)

  // handlers
  const handleDelete = async () => {
    setIsLoading(true)
    try {
      const body = {
        id: phoneNumber.id,
      }
      await plansApi.deletePhoneNumber(planId, body)
      // Is setIsLoading is after the Try/Catch it causes a No-Op Error on unmounted components in react because the dispatch below removes this component and setIsLoading cannot be called anymore
      setIsLoading(false)
      // Update local state
      dispatch(deletePhoneNumber({ phoneId: phoneNumber.id }))
      notificator.success(`${phoneNumber.e164Format} ${SuccessMessagesEnum.DeletePhoneNumber}`)
    } catch (error: any) {
      setIsLoading(false)
      errorHandler.handleError(error)
    }
  }

  return (
    <ListItem data-test={phoneNumber.e164Format}>
      <Container extraStyles={{ hasBorder: true, position: 'relative' }}>
        {isLoading && <Loading />}
        <div>
          <p>{phoneNumber.e164Format}</p>
        </div>

        <div className="buttons">
          <Button
            dataTest={'viewPhoneButton'}
            variant="outlined"
            size="small"
            invertOnHover
            onClick={() => history.push(`/plans/phones/${phoneNumber.id}`)}
          >
            View
          </Button>

          <RemovePhoneNumberButton
            handleDelete={handleDelete}
            isLoading={isLoading}
            phoneNumber={phoneNumber.e164Format}
          />
        </div>
      </Container>
    </ListItem>
  )
}

const ListItem = styled.li`
  flex: 1 1 100%;
  min-width: 19em;
  max-width: 20em;

  p {
    font-weight: 500;
    font-size: 1em;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      align-items: center;
      gap: 0.325rem;
    }
  }
`

export default PhoneListPhoneNumber
