import { useState } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
// components
import { Button, Container, Loading } from '../../../components'
// types
import { IAddPhoneNumberRequest } from '../types'
// state
import { useAppDispatch } from '../../../app/store'
import { addPhoneNumber } from '../plansSlice'
// services
import plansApi from '../plansApi'
import { notificator, errorHandler, SuccessMessagesEnum } from '../../../utils'

interface IProps {
  planId: string
}

const AddPhoneNumberForm = ({ planId }: IProps) => {
  // hooks
  const dispatch = useAppDispatch()

  // local state
  const initialState = {
    phoneNumber: '',
  }
  const [data, setData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // handlers
  const handleSumbit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true)
    try {
      e.preventDefault()

      const convertFormDataToApiRequest = (formData: typeof data): IAddPhoneNumberRequest => {
        return {
          e164Format: formData.phoneNumber,
          primary: true,
        }
      }

      const body = convertFormDataToApiRequest(data)
      const newPhone = await plansApi.addPhoneNumber(planId, body)

      dispatch(addPhoneNumber(newPhone))

      notificator.success(`${newPhone.e164Format} ${SuccessMessagesEnum.AddPhoneNumber}`)
      if (isError) setIsError(false)
      setData(initialState)
    } catch (error: any) {
      errorHandler.handleError(error)
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: prop, value } = e.target
    setData((prev) => ({ ...prev, [prop]: value }))
  }

  // variables
  const hasEmptyField = !data.phoneNumber

  return (
    <Form data-test="planAddPhoneNumberForm">
      <h2>Add phone numbers</h2>
      <Container extraStyles={{ hasBorder: true, position: 'relative' }}>
        {isLoading && <Loading />}

        <div className="inputs">
          <TextField
            name="phoneNumber"
            variant="standard"
            id="phoneNumber"
            label="Phone number"
            type="tel"
            required
            onChange={handleChange}
            value={data.phoneNumber}
            data-test="planPhoneNumberInput"
            error={isError}
          />
        </div>

        <Button onClick={handleSumbit} type="button" disabled={hasEmptyField} dataTest="planAddPhoneNumberButton">
          Add
        </Button>
      </Container>
    </Form>
  )
}

const Form = styled.form`
  max-width: 42em;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 0.875em;
      width: 20em;
      padding-block-end: 1.5em;
    }
  }
`

export default AddPhoneNumberForm
