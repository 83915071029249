import { DefaultTheme, ThemeProvider } from 'styled-components'

const theme: DefaultTheme = {
  borderRadius: '5px',
  spacing: '0.25rem',
  typography: {
    small: '0.75rem',
    p: '0.875rem',
    h1: '1.5em',
    h2: '1.25em',
    h3: '1.1em',
  },

  breakpoints: {
    xs: '0',
    sm: '37.5em', // 600px
    md: '56.25em', // 900px
    lg: '75em', // 1200px
    xl: '96em', // 1536px
  },

  colors: {
    text: {
      light: '#665b57',
      normal: '#363636',
      dark: '#0e0908',
    },
    commons: {
      light: '#FAFAFA',
      normal: '#333333',
      dark: '#0f0f0f',
    },
    info: {
      light: '#2d9cdb',
      normal: '#007BC0',
      dark: '#32325d',
    },
    secondary: {
      light: '#CFD8DC',
      normal: '#78909C',
      dark: '#455A64',
    },
    warning: {
      light: '#FFF59D',
      normal: '#FFEB3B',
      dark: '#FBC02D',
    },
    danger: {
      light: '#EF5350',
      normal: '#fa1d42',
      dark: '#C62828',
    },
    main: {
      light: '#B2EBF2',
      normal: '#0097A7',
      dark: '#006064',
    },
    success: {
      light: '#69F0AE',
      normal: '#27ae60',
      dark: '#1B5E20',
    },
  },

  shadows: {
    normal: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    light: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    intense: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
}

interface IProps {
  children: React.ReactNode
}

const Theme = ({ children }: IProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
