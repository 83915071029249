// components
import { Button } from '../../components'
// types
import { IRow } from '../../components/Table/types'
import { IGetPlansResponse, ISearchQuery } from './types'
// utils
import { history } from '../../utils'

export const convertPlanToTableRow = (res: IGetPlansResponse) => {
  const { data, count, offset, limit } = res
  const rows: IRow[] = data.map((plan) => ({
    id: plan.id,
    name: plan?.name || 'Not defined',
    payer: plan?.payer?.name || 'Not defined',
    phoneNumbers: plan?.phoneNumbers?.length,
    button: (
      <Button
        dataTest={'viewPlanButton'}
        variant="outlined"
        size="small"
        invertOnHover
        onClick={() => history.push(`/plans/${plan.id}`)}
      >
        View
      </Button>
    ),
  }))

  return { rows, count, offset, limit }
}

export const uriEncodeQuery = (data: ISearchQuery) => {
  const filters: string[] = []
  const { name, noPhoneNumber, hasPhoneNumber, phoneNumber } = data

  if (name) {
    filters.push(
      `Filter=${encodeUri({
        path: 'name',
        operator: 'like',
        value: `%${name}%`,
      })}`,
    )
  }

  if (phoneNumber) {
    filters.push(
      `Filter=${encodeUri({
        path: 'phoneNumbers.e164Format',
        operator: 'like',
        value: `%${phoneNumber}%`,
      })}`,
    )
  }

  if (noPhoneNumber) {
    filters.push(
      `Filter=${encodeUri({
        path: 'phoneNumbers',
        operator: 'none',
      })}`,
    )
  }

  if (hasPhoneNumber) {
    filters.push(
      `Filter=${encodeUri({
        path: 'phoneNumbers',
        operator: 'any',
      })}`,
    )
  }

  // early returns
  if (!filters.length) return ''

  let query = '?'
  filters.forEach((filter, i) => {
    // Add the & on the left side to all filters except the first one
    query += `${i === 0 ? '' : '&'}${filter}`
  })
  return query
}

const encodeUri = (value: { [k: string]: string }) => encodeURIComponent(JSON.stringify(value))

const plansUtils = { uriEncodeQuery, convertPlanToTableRow }
export default plansUtils
