import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
// types
import { IColumn, IPagination, IRow } from './types'
// components
import { Loading } from '../../components'
import TableBody from './TableBody'
import TableHead from './TableHead'
import TableFooter from './TableFooter'

interface IProps {
  isLoading: boolean
  maxHeight: string
  rows: IRow[]
  columns: IColumn[]
  showHeader?: true
  pagination?: IPagination
}

const CustomPaginationActionsTable = ({ showHeader, pagination, isLoading, rows, columns, maxHeight }: IProps) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight, minHeight: isLoading ? maxHeight : 0, position: 'relative' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <Table stickyHeader aria-label="sticky table pagination" size="small">
          {showHeader && <TableHead columns={columns} />}

          <TableBody columns={columns} rows={rows} />

          {pagination && <TableFooter pagination={pagination} />}
        </Table>
      )}
    </TableContainer>
  )
}

export default CustomPaginationActionsTable
