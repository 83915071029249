import styled from 'styled-components'
// components
import { LoadingAnimation } from '../../components'
// types
import { IProps as ILoadingAnimationProps } from './LoadingAnimation'

interface IStyles {
  top?: string
}

export interface IProps extends IStyles {
  message?: string
  scProps?: IStyles
  animationProps?: ILoadingAnimationProps
}

const Loading = ({ message, scProps, animationProps }: IProps) => {
  return (
    <Wrapper {...scProps}>
      <div>
        <LoadingAnimation {...animationProps} data-test="loadingComponent" />
        {message && <h2>{message}</h2>}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<IStyles>`
  backdrop-filter: blur(2px);
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > div {
    position: relative;
    top: ${(props) => props.top || '0'};
  }
`

export default Loading
