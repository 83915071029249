import { useState, cloneElement } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// components
import { Button, Container, Loading } from '..'

interface IProps {
  title?: string
  message?: string
  onConfirm: () => void | Promise<void>
  Toggler: JSX.Element
  CancelButton?: JSX.Element
  ConfirmButton?: JSX.Element
  isLoading?: boolean
}

const ConfirmationModal = ({ Toggler, CancelButton, ConfirmButton, isLoading, message, title, onConfirm }: IProps) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await onConfirm()
    setOpen(false)
  }

  return (
    <div>
      {cloneElement(Toggler, { onClick: handleClickOpen })}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <Container>
          {isLoading && <Loading />}
          {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            {message && (
              <DialogContentText id="alert-dialog-description">{message || 'Are you sure?'}</DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {CancelButton ? (
              cloneElement(CancelButton, { onClick: handleClose })
            ) : (
              <Button dataTest={'modalCancelButton'} size="small" color="danger" onClick={handleClose}>
                Cancel
              </Button>
            )}

            {ConfirmButton ? (
              ConfirmButton
            ) : (
              <Button
                dataTest="modalConfirmButton"
                size="small"
                color="info"
                onClick={handleConfirm}
                disabled={isLoading}
              >
                Confirm
              </Button>
            )}
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  )
}

export default ConfirmationModal
