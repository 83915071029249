import Container from '@mui/material/Container'
import { Auth } from 'aws-amplify'
// components
import { Theme, GlobalStyles } from './components'
import Router from './pages/Router'
// services
import awsConfig from './services/aws/amplify'

Auth.configure(awsConfig)

const App = () => {
  return (
    <Theme>
      <GlobalStyles />
      <Container maxWidth="xl">
        <Router />
      </Container>
    </Theme>
  )
}

export default App
