import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
// components
import { Layout, Loading } from '../../components'
import { PlanSummary, PlanPhonesList } from '../../slices/plans/components'
// state
import { useAppDispatch, useAppSelector } from '../../app/store'
import { setPlan } from '../../slices/plans/plansSlice'
import plansApi from '../../slices/plans/plansApi'
// services

type ParamTypes = {
  id: string
}

const Plan = () => {
  // global state
  const { plan } = useAppSelector((state) => state.plans)

  // hooks
  const dispatch = useAppDispatch()
  const { id } = useParams<ParamTypes>()

  // local state
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // effects
  useEffect(() => {
    if (!plan && id) {
      const getPlan = async (id: string) => {
        setIsLoading(true)
        try {
          const plan = await plansApi.getPlan(id)
          dispatch(setPlan(plan))
        } catch (error) {
          setIsError(true)
        }
        setIsLoading(false)
      }

      getPlan(id)
    }
  }, [plan, id, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setPlan(null))
    }
  }, [dispatch])

  return (
    <Layout>
      {isLoading && <Loading message={'Loading plan details...'} />}

      {plan && !isError && (
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={4}>
            <PlanSummary plan={plan} />
          </Grid>
          <Grid item xs={12} md={8}>
            <PlanPhonesList phoneNumbers={plan.phoneNumbers} planId={plan.id} />
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

export default Plan
