interface IAwsConfig {
  region?: string
  userPoolId?: string
  userPoolWebClientId?: string
}

const userPool = JSON.parse(process.env?.REACT_APP_user_pool || '{}')
export const awsConfig: IAwsConfig = {
  region: userPool.region,
  userPoolId: userPool.id,
  userPoolWebClientId: userPool.client_id,
}

export default awsConfig
