import styled from 'styled-components'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export const StyledTableCell = styled(TableCell)`
  &.${tableCellClasses.root} {
    font-size: '1rem';
    font-weight: 500;
    text-align: center;
  }
  &.${tableCellClasses.head} {
    background-color: ${({ theme }) => theme.colors.main.normal};
    color: ${({ theme }) => theme.colors.commons.light};

    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.commons.light};
    }
  }

  &.${tableCellClasses.body} {
    &:first-of-type {
      font-weight: 700;
    }

    &.danger {
      color: ${({ theme }) => theme.colors.danger.normal};
    }
  }
`
