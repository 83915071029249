import cx from 'classnames'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
// styled components
import { StyledTableCell } from './styles.styled'
// types
import { IColumn, IRow } from './types'

interface IProps {
  columns: IColumn[]
  rows: IRow[]
}

const TableBodyComponent = ({ columns, rows }: IProps) => {
  return (
    <TableBody>
      {rows.map((row, i) => (
        <TableRow key={i} hover={true} data-test={row.id}>
          {columns?.map((column, i) => (
            <StyledTableCell
              key={`${row.id}-${i}`}
              className={cx({ danger: row[column.id as keyof IRow] === 0 })}
              style={{ ...column.styles }}
            >
              {row[column.id as keyof IRow]}
            </StyledTableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

export default TableBodyComponent
