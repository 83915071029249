import { IPhoneNumber } from './types'
// types
import { IAddPhoneNumberRequest, IGetPlansResponse, IPlan, IRemovePhoneNumberRequest, PlanPathsEnum } from './types'
// utils
import http from '../../utils/http'

export const getPlans = async (url: string, searchQuery = '') => {
  const { data }: { data: IGetPlansResponse } = await http.get(`${url}${searchQuery}`)
  return data
}

export const getPlan = async (planId: string) => {
  const { data }: { data: IPlan } = await http.get(`${PlanPathsEnum.plans}/${planId}`)
  return data
}

export const addPhoneNumber = async (planId: string, body: IAddPhoneNumberRequest) => {
  const { data }: { data: IPhoneNumber } = await http.post(`${PlanPathsEnum.plans}/${planId}/phoneNumbers`, body)
  return data
}

export const deletePhoneNumber = async (planId: string, body: IRemovePhoneNumberRequest) => {
  const { data }: { data: IPhoneNumber } = await http.delete(`${PlanPathsEnum.plans}/${planId}/phoneNumbers/${body.id}`)
  return data
}

const plansApi = {
  getPlans,
  getPlan,
  addPhoneNumber,
  deletePhoneNumber,
}

export default plansApi
