import styled from 'styled-components'
// components
import { PhonesListPhoneNumber } from '.'
// state
import { useAppSelector } from '../../../app/store'
import { IPhoneNumber } from '../types'

const PhonesList = () => {
  const { phoneNumbers, id: planId } = useAppSelector((state) => state.plans.plan || { phoneNumbers: [], id: '' })
  return (
    <Wrapper>
      <h2>Phone Numbers</h2>

      <List>
        {(phoneNumbers as IPhoneNumber[]).map((phoneNumber) => (
          <PhonesListPhoneNumber key={phoneNumber.id} phoneNumber={phoneNumber} planId={planId} />
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-block-end: 2em;
`

const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`

export default PhonesList
