import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
// styled components
import { StyledTableCell } from './styles.styled'
// types
import { IColumn } from './types'

interface IProps {
  columns: IColumn[]
}

const TableHeadComponent = ({ columns }: IProps) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <StyledTableCell key={column.id} component="th" style={{ ...(column?.styles || {}) }}>
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeadComponent
