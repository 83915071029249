import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// components
import { Table, ErrorMessage } from '../../../components'
// types and utils
import { IColumn } from '../../../components/Table/types'
import { ErrorMessagesEnum } from '../../../utils/errorHandler'
import { ScrollTarget } from '../../../utils/dom'
import { convertPlanToTableRow } from '../utils'
// state
import { useAppSelector } from '../../../app/store'
// api
import { useSearchPlans } from '../hooks'
import { getSearchQuery } from '../plansSlice'

export const columns: IColumn[] = [
  { id: 'name', label: 'Name', styles: { textAlign: 'left', width: '40ch' } },
  { id: 'payer', label: 'Payer', styles: { textAlign: 'left', width: '40ch' } },
  { id: 'phoneNumbers', label: 'Phone Numbers' },
  { id: 'useCases', label: 'Use Cases' },
  { id: 'conversationSuccess', label: 'Conversation Success' },
  { id: 'button', label: 'View' },
]

const SearchResults = () => {
  // global state
  const searchQuery = useAppSelector(getSearchQuery)

  // local state
  const initialQueryState = useMemo(() => ({ searchQuery, page: 0, limit: 0 }), [searchQuery])
  const [query, setQuery] = useState(initialQueryState)

  // api
  const { data, isLoading, isError } = useSearchPlans(query)
  const { rows } = data?.data?.length ? convertPlanToTableRow(data) : { rows: [] }

  // effects
  useEffect(() => {
    if (!!searchQuery && searchQuery !== query.searchQuery) {
      setQuery({ ...initialQueryState, searchQuery })
    }
  }, [searchQuery, initialQueryState, query.searchQuery])

  useEffect(() => {
    // Set the limit from the initial response because by default we don't use a static limit and we want to use the one defined by the backend
    if (data && query.limit === 0) setQuery((prev) => ({ ...prev, limit: data.limit }))
  }, [data, query.limit])

  // handlers
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    event?.preventDefault()
    setQuery((prev) => ({
      ...prev,
      page,
      limit: data?.limit || 0,
    }))
  }

  // variables
  const message = data?.count ? `${data.count} plans` : 'No plans match the query'
  const { offset, count, limit: rowsPerPage } = data || { count: 0, limit: 0, offset: 0 }
  const page = offset ? Math.ceil(offset / rowsPerPage) : 0
  const pagination = {
    count,
    rowsPerPage,
    page,
    handleChangePage,
  }

  return (
    <Wrapper data-test="plansSearchResultTable">
      <ScrollTarget id="searchResultsTable" offset="-80px" />
      <h2>
        {isLoading ? 'Fetching the plans...' : ''}
        {isError ? <ErrorMessage message={ErrorMessagesEnum.Unknown} /> : ''}
        {data && `Results: ${message}`}
      </h2>

      <Table columns={columns} rows={rows} isLoading={isLoading} pagination={pagination} maxHeight="calc(100vh - 10em)" showHeader />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  table {
    overflow-y: scroll;
  }
`
export default SearchResults
